export enum BoardActionType {
    UPDATE_BOARD="UPDATE_BOARD",
    UPDATE_PERSPECTIVE="UPDATE_PERSPECTIVE",
    UPDATE_LOCKED="UPDATE_LOCKED",
    LEFT_CLICK_SQUARE="LEFT_CLICK_SQUARE",
    LEFT_DRAGGING_START="LEFT_DRAGGING_START",
    LEFT_DROP="LEFT_DROP",
    RIGHT_CLICK_SQUARE="RIGHT_CLICK_SQUARE",
    PICK_PROMOTE="PICK_PROMOTE",
    CANCEL_PROMOTE="CANCEL_PROMOTE",
    MARK_SQUARE="MARK_SQUARE",
    UNMARK_SQUARE="UNMARK_SQUARE",
    PUSH_PREMOVE="PUSH_PREMOVE",
    POP_PREMOVE="POP_PREMOVE",
    CLEAR_SELECTIONS="CLEAR_SELECTIONS",
}
export abstract class BoardAction {
    abstract type: BoardActionType;
    abstract payload: any;
}